<template>
  <div :class="`WebCastComments ${showComments ? 'show' : ''}`" @click="toggleShowComments($event, false)">
    <div class="WebCastComments-content">
      <div class="WebCastComments-comments-header">
        <button class="WebCastComments-comments-close" @click="toggleShowComments($event, false)">
          <iconic name="arrowLeft" />
        </button>

        <h3>Comentarios ({{ comments.length }})</h3>
      </div>
      <div class="WebCastComments-comments-body modal-margin">
        <div :class="`WebCastComments-comments-comment ${comment.reply ? 'reply-message' : ''}`" v-for="(comment, i) in comments" :key="i">
          <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="Icono perfil webcast" />
          <div>
            <p class="author">{{ comment.author }}</p>
            <p class="text-message">{{ comment.textMessage }}</p>
            <button class="reply">Reply</button>
          </div>
        </div>
      </div>
      <div class="WebCastComments-comments-footer">
        <div class="inputs-sendMessage modal-margin">
          <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="Icono perfil webcast" />
          <input type="text" name="" placeholder="Escribe tu comentario" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      showComments: false,
      comments: [
        {
          author: "Andrea Pérez",
          textMessage:
            "¡Qué risa me dio escuchando las anécdotas divertidas de estas modelos! La moda también tiene su lado cómico, y estas chicas lo demuestran.",
          reply: false,
        },
        {
          author: "Elena Rodríguez",
          textMessage: "¡Totalmente de acuerdo, Andrea!",
          reply: true,
        },
        {
          author: "María López",
          textMessage:
            "Estas modelos no solo son hermosas, ¡también son increíblemente graciosas! Sus anécdotas son una muestra de que el humor está en todas partes.",
          reply: false,
        },
        {
          author: "María López",
          textMessage:
            "Estas modelos no solo son hermosas, ¡también son increíblemente graciosas! Sus anécdotas son una muestra de que el humor está en todas partes.",
          reply: false,
        },
        {
          author: "María López",
          textMessage:
            "Estas modelos no solo son hermosas, ¡también son increíblemente graciosas! Sus anécdotas son una muestra de que el humor está en todas partes.",
          reply: false,
        },
        {
          author: "María López",
          textMessage:
            "Estas modelos no solo son hermosas, ¡también son increíblemente graciosas! Sus anécdotas son una muestra de que el humor está en todas partes.",
          reply: false,
        },
      ],
    };
  },
  methods: {
    toggleShowComments(evt, flag) {
      // Se valida si se le dio click al fondo de los comentarios o al boton de cerrar
      const isBackgroundClicked = evt.target.classList.contains("WebCastComments") || evt.target.classList.contains("svg-icon");
      if (isBackgroundClicked || flag) {
        this.showComments = flag;
      }
    },
  },
};
</script>
<style lang="scss">
.WebCastComments {
  position: absolute;
  top: 0;
  z-index: 30;
  width: 100%;
  height: 100%;
  visibility: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  transition: visibility 0.3s ease-in-out;

  &-content {
    transform: translateY(100%);
    height: 60%;
    width: 100%;
    background: #fff;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    .WebCastComments-comments {
      &-header {
        padding: 12px 20px;
        h3 {
          font-size: 22px;
          text-align: center;
        }
        button {
          position: absolute;
        }
      }
      &-close {
        font-size: 20px;
      }
      &-body,
      &-footer {
        img {
          width: 40px;
          height: 40px;
        }
      }
      &-body {
        margin-left: 20px;
        min-height: 100px;
        overflow: auto;
        .WebCastComments-comments-comment {
          display: flex;
          gap: 10px;
          margin-bottom: 30px;
          margin-right: 5px;
          .author {
            font-weight: bold;
            margin-bottom: 5px;
          }
          .reply {
            margin-top: 10px;
            font-weight: bold;
          }
          &.reply-message {
            margin-left: 50px;
          }
        }
      }

      &-footer {
        width: 100%;
        gap: 10px;
        padding: 20px;
        background: #fff;
        input {
          width: 100%;
          height: 40px;
          padding: 10px;
          border-radius: 22px;
          border: 1px solid gray;
        }
        .inputs-sendMessage {
          display: flex;
          gap: 10px;
        }
      }
    }
  }

  &.show {
    visibility: visible;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
  }
  &.show > &-content {
    transform: translateY(0%);
  }

  @include MobileSmall {
    &-content {
      height: 55%;
    }
  }
  ::-webkit-scrollbar {
    width: 17px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(151, 151, 151);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #4d4d4d;
  }
}
</style>
